import React, { Component } from "react";
import Header from "../../Header";
import Helmet from "../../Helmet";
import Zoom from "react-reveal/Zoom";
import Footer from "../../Footer";
import Next from "../../Next";

class Saint_Malo2 extends Component {
  render() {
    return (
      <div>
        <Helmet
          title={"Saint-Malo (2) Découverte de la Normandie et du Littoral"}
          link={"http://decouverte.guillaumequeste.fr/saint-malo2"}
        />
        <Header />
        <Footer />
        <div className="bodyLittoral">
          <h1 className="title">Saint-Malo (2)</h1>
          <Next next={"saint-malo1"} />
          <div className="divPhoto">
            <img
              src={require("../../../img/littoral/saint-malo2/saint-malo201.jpg")}
              alt="saint-malo102"
              className="photo"
            />
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo202.jpg")}
                alt="saint-malo202"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo203.jpg")}
                alt="saint-malo203"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo204.jpg")}
                alt="saint-malo204"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo205.jpg")}
                alt="saint-malo205"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo206.jpg")}
                alt="saint-malo206"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo207.jpg")}
                alt="saint-malo207"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo208.jpg")}
                alt="saint-malo208"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo209.jpg")}
                alt="saint-malo209"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo210.jpg")}
                alt="saint-malo210"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo211.jpg")}
                alt="saint-malo211"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo212.jpg")}
                alt="saint-malo212"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo213.jpg")}
                alt="saint-malo213"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo214.jpg")}
                alt="saint-malo214"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo215.jpg")}
                alt="saint-malo215"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo216.jpg")}
                alt="saint-malo216"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo217.jpg")}
                alt="saint-malo217"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo218.jpg")}
                alt="saint-malo218"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo219.jpg")}
                alt="saint-malo219"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo220.jpg")}
                alt="saint-malo220"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo221.jpg")}
                alt="saint-malo221"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo222.jpg")}
                alt="saint-malo222"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo223.jpg")}
                alt="saint-malo223"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo224.jpg")}
                alt="saint-malo224"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo225.jpg")}
                alt="saint-malo225"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo226.jpg")}
                alt="saint-malo226"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo227.jpg")}
                alt="saint-malo227"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo228.jpg")}
                alt="saint-malo228"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo229.jpg")}
                alt="saint-malo229"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo230.jpg")}
                alt="saint-malo230"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo231.jpg")}
                alt="saint-malo231"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo232.jpg")}
                alt="saint-malo232"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo233.jpg")}
                alt="saint-malo233"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo234.jpg")}
                alt="saint-malo234"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo235.jpg")}
                alt="saint-malo235"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo236.jpg")}
                alt="saint-malo236"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo237.jpg")}
                alt="saint-malo237"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo238.jpg")}
                alt="saint-malo238"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo239.jpg")}
                alt="saint-malo239"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo240.jpg")}
                alt="saint-malo240"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo241.jpg")}
                alt="saint-malo241"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/saint-malo2/saint-malo242.jpg")}
                alt="saint-malo242"
                className="photo"
              />
            </Zoom>
          </div>
        </div>
      </div>
    );
  }
}

export default Saint_Malo2;
