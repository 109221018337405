import React, { Component } from "react";
import Header from "../../Header";
import Helmet from "../../Helmet";
import Zoom from "react-reveal/Zoom";
import Footer from "../../Footer";
import Next from "../../Next";
import Previous from "../../Previous";

class Sotteville extends Component {
  render() {
    return (
      <div>
        <Helmet
          title={"Sotteville-sur-Mer Découverte de la Normandie et du Littoral"}
          link={"http://decouverte.guillaumequeste.fr/sotteville"}
        />
        <Header />
        <Footer />
        <div className="bodyLittoral">
          <Previous prev={"veules"} />
          <h1 className="title">Sotteville-sur-Mer</h1>
          <Next next={"saint-aubin"} />
          <div className="divPhoto">
            <img
              src={require("../../../img/littoral/sotteville/sotteville01.jpg")}
              alt="sotteville01"
              className="photo"
            />
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/sotteville/sotteville02.jpg")}
                alt="sotteville02"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/sotteville/sotteville03.jpg")}
                alt="sotteville03"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/sotteville/sotteville04.jpg")}
                alt="sotteville04"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/sotteville/sotteville05.jpg")}
                alt="sotteville05"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/sotteville/sotteville06.jpg")}
                alt="sotteville06"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/sotteville/sotteville07.jpg")}
                alt="sotteville07"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/sotteville/sotteville08.jpg")}
                alt="sotteville08"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/sotteville/sotteville09.jpg")}
                alt="sotteville09"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/sotteville/sotteville10.jpg")}
                alt="sotteville10"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/sotteville/sotteville11.jpg")}
                alt="sotteville11"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/sotteville/sotteville12.jpg")}
                alt="sotteville12"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/sotteville/sotteville13.jpg")}
                alt="sotteville13"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/sotteville/sotteville14.jpg")}
                alt="sotteville14"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/sotteville/sotteville15.jpg")}
                alt="sotteville15"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/sotteville/sotteville16.jpg")}
                alt="sotteville16"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/sotteville/sotteville17.jpg")}
                alt="sotteville17"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/sotteville/sotteville18.jpg")}
                alt="sotteville18"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/sotteville/sotteville19.jpg")}
                alt="sotteville19"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/sotteville/sotteville20.jpg")}
                alt="sotteville20"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/sotteville/sotteville21.jpg")}
                alt="sotteville21"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/sotteville/sotteville22.jpg")}
                alt="sotteville22"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/sotteville/sotteville23.jpg")}
                alt="sotteville23"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/sotteville/sotteville24.jpg")}
                alt="sotteville24"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/sotteville/sotteville25.jpg")}
                alt="sotteville25"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/sotteville/sotteville26.jpg")}
                alt="sotteville26"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/sotteville/sotteville27.jpg")}
                alt="sotteville27"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/sotteville/sotteville28.jpg")}
                alt="sotteville28"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/sotteville/sotteville29.jpg")}
                alt="sotteville29"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/sotteville/sotteville30.jpg")}
                alt="sotteville30"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/sotteville/sotteville31.jpg")}
                alt="sotteville31"
                className="photo"
              />
            </Zoom>
          </div>
        </div>
      </div>
    );
  }
}

export default Sotteville;
