import React, { Component } from "react";
import Header from "../../Header";
import Helmet from "../../Helmet";
import Zoom from "react-reveal/Zoom";
import Footer from "../../Footer";
import Next from "../../Next";
import Previous from "../../Previous";

class Dieppe extends Component {
  render() {
    return (
      <div>
        <Helmet
          title={"Dieppe Découverte de la Normandie et du Littoral"}
          link={"http://decouverte.guillaumequeste.fr/dieppe"}
        />
        <Header />
        <Footer />
        <div className="bodyLittoral">
          <Previous prev={"pourville"} />
          <h1 className="title">Dieppe</h1>
          <Next next={"puys"} />
          <div className="divPhoto">
            <img
              src={require("../../../img/littoral/dieppe/dieppe01.jpg")}
              alt="dieppe01"
              className="photo"
            />
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe02.jpg")}
                alt="dieppe02"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe03.jpg")}
                alt="dieppe03"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe04.jpg")}
                alt="dieppe04"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe05.jpg")}
                alt="dieppe05"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe06.jpg")}
                alt="dieppe06"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe07.jpg")}
                alt="dieppe07"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe08.jpg")}
                alt="dieppe08"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe09.jpg")}
                alt="dieppe09"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe10.jpg")}
                alt="dieppe10"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe11.jpg")}
                alt="dieppe11"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe12.jpg")}
                alt="dieppe12"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe13.png")}
                alt="dieppe13"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe14.jpg")}
                alt="dieppe14"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe15.jpg")}
                alt="dieppe15"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe16.jpg")}
                alt="dieppe16"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe17.jpg")}
                alt="dieppe17"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe18.jpg")}
                alt="dieppe18"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe19.jpg")}
                alt="dieppe19"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe20.jpg")}
                alt="dieppe20"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe21.jpg")}
                alt="dieppe21"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe22.jpg")}
                alt="dieppe22"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe23.jpg")}
                alt="dieppe23"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe24.jpg")}
                alt="dieppe24"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe25.jpg")}
                alt="dieppe25"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe26.jpg")}
                alt="dieppe26"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe27.jpg")}
                alt="dieppe27"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe28.jpg")}
                alt="dieppe28"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe29.jpg")}
                alt="dieppe29"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe30.jpg")}
                alt="dieppe30"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe31.jpg")}
                alt="dieppe31"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe32.jpg")}
                alt="dieppe32"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe33.jpg")}
                alt="dieppe33"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe34.jpg")}
                alt="dieppe34"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe35.jpg")}
                alt="dieppe35"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe36.jpg")}
                alt="dieppe36"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe37.jpg")}
                alt="dieppe37"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe38.jpg")}
                alt="dieppe38"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe39.jpg")}
                alt="dieppe39"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe40.jpg")}
                alt="dieppe40"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe41.jpg")}
                alt="dieppe41"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe42.jpg")}
                alt="dieppe42"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe43.jpg")}
                alt="dieppe43"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe44.jpg")}
                alt="dieppe44"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe45.jpg")}
                alt="dieppe45"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe46.jpg")}
                alt="dieppe46"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe47.jpg")}
                alt="dieppe47"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/dieppe/dieppe48.jpg")}
                alt="dieppe48"
                className="photo"
              />
            </Zoom>
          </div>
        </div>
      </div>
    );
  }
}

export default Dieppe;
