import React, { Component } from "react";
import Header from "../../Header";
import Helmet from "../../Helmet";
import Zoom from "react-reveal/Zoom";
import Footer from "../../Footer";
import Next from "../../Next";
import Previous from "../../Previous";

class Le_Treport extends Component {
  render() {
    return (
      <div>
        <Helmet
          title={"Le Tréport Découverte de la Normandie et du Littoral"}
          link={"http://decouverte.guillaumequeste.fr/le_treport"}
        />
        <Header />
        <Footer />
        <div className="bodyLittoral">
          <Previous prev={"mesnil_val"} />
          <h1 className="title">Le Tréport</h1>
          <Next next={"mers"} />
          <div className="divPhoto">
            <img
              src={require("../../../img/littoral/le_treport/le_treport01.jpg")}
              alt="le_treport01"
              className="photo"
            />
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/le_treport/le_treport02.jpg")}
                alt="le_treport02"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/le_treport/le_treport03.jpg")}
                alt="le_treport03"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/le_treport/le_treport04.jpg")}
                alt="le_treport04"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/le_treport/le_treport05.jpg")}
                alt="le_treport05"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/le_treport/le_treport06.jpg")}
                alt="le_treport06"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/le_treport/le_treport07.jpg")}
                alt="le_treport07"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/le_treport/le_treport08.jpg")}
                alt="le_treport08"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/le_treport/le_treport09.jpg")}
                alt="le_treport09"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/le_treport/le_treport10.jpg")}
                alt="le_treport10"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/le_treport/le_treport11.jpg")}
                alt="le_treport11"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/le_treport/le_treport12.jpg")}
                alt="le_treport12"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/le_treport/le_treport13.jpg")}
                alt="le_treport13"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/le_treport/le_treport14.jpg")}
                alt="le_treport14"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/le_treport/le_treport15.jpg")}
                alt="le_treport15"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/le_treport/le_treport16.jpg")}
                alt="le_treport16"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/le_treport/le_treport17.jpg")}
                alt="le_treport17"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/le_treport/le_treport18.jpg")}
                alt="le_treport18"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/le_treport/le_treport19.jpg")}
                alt="le_treport19"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/le_treport/le_treport20.jpg")}
                alt="le_treport20"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/le_treport/le_treport21.jpg")}
                alt="le_treport21"
                className="photo"
              />
            </Zoom>
          </div>
          <div className="divPhoto">
            <Zoom>
              <img
                src={require("../../../img/littoral/le_treport/le_treport22.jpg")}
                alt="le_treport22"
                className="photo"
              />
            </Zoom>
          </div>
        </div>
      </div>
    );
  }
}

export default Le_Treport;
